import React from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
  ContentfulComposePage,
  ContentfulComposeSeo,
  ContentfulPageRedirect,
  RedirectPageQuery,
} from '@/../graphql-types';
import Layout from '@/components/layout/Layout';
import SEO from '@/components/SEO';
import * as Constants from '@/types/Constants';
import PageRedirect from '@/components/contentful/pages/Redirect';

export const query = graphql`
  query RedirectPage($id: String!, $node_locale: String!) {
    contentfulComposePage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      internal {
        type
      }
      slug
      title
      seo {
        createdAt
        updatedAt
        description
        id
        keywords
        pageType
        no_follow
        no_index
        title
        canonicalUrl
        featuredImage {
          description
          file {
            url
          }
        }
      }
      content {
        ... on ContentfulPageRedirect {
          id
          internal {
            type
          }
          destinationUrl
        }
      }
    }
  }
`;

const Page: React.FC<PageProps<RedirectPageQuery>> = (props) => {
  const { location, data } = props;

  return (
    <>
      <SEO
        location={location}
        defaultPageType={data?.contentfulComposePage?.content?.internal?.type}
        defaultTitle={data?.contentfulComposePage?.title}
        seo={data?.contentfulComposePage?.seo as ContentfulComposeSeo}
      />
      <Layout
        location={location}
        page={data?.contentfulComposePage as ContentfulComposePage}
        // header={pageHeader as ContentfulComponentSiteHeader}
        // footer={pageFooter as ContentfulComponentSiteFooter}
      >
        <PageRedirect
          data={data?.contentfulComposePage?.content as ContentfulPageRedirect}
        />
      </Layout>
    </>
  );
};

export default Page;
