import React, { useEffect } from 'react';
import { ContentfulPageRedirect } from '@/../graphql-types';
import { navigate } from 'gatsby';

interface PageRedirectProps {
  data?: ContentfulPageRedirect;
}
const PageRedirect: React.FC<PageRedirectProps> = (props) => {
  const { data } = props;

  // const [redirectTo, setRedirectTo] = React.useState(String);

  React.useEffect(() => {
    const url = new URL(location?.href);
    const queryString = url?.search;
    const redirectTo = data?.destinationUrl;

    // let addSlash = '/';

    // const hasTrailingSlash = redirectTo.charAt(redirectTo.length - 1) === '/';
    // if (hasTrailingSlash || !queryString) {
    //   addSlash = '';
    // }

    navigate(redirectTo + queryString);
  }, []);

  return null;
};
export default PageRedirect;
